@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
  body {
    margin: 0 !important;
  }
}

.main-container {
  font-family: 'Lato';
  width: fit-content;
  margin-left: 0;
  margin-right: 0;
  /* color: #000; */
  width: 100%;
}

.ck-content {
  font-family: 'Lato';
  line-height: 1.6;
  word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
  /* min-width: 795px; */
  /* max-width: 795px; */
  width: 100%;
}

.ck.ck-editor__main > .ck-editor__editable {
  background-color: #111421;
  border-color: #2e3a59 !important;
  min-height: 500px;
  max-height: 500px;
  overflow-y: auto;
}
